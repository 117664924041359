<template>
    <div>
        <VueMultiselect
            :id="`ac_${id}`"
            v-model="mselect"
            :options="options"
            :placeholder="placeholder"
            :name="`ac_${name}`"
            :label="record[0]"
            :show-labels="true"
            :reset-after="multiple"
            select-label="Kattints vagy Enter billentyű a kiválasztáshoz"
            deselect-label="Kattints vagy Enter billentyű a törléshez"
            selected-label="Kiválasztva"
            :class="`${cssClass}`"
            :track-by="record[0]"
            @select="select"
        >
            <template #singleLabel="{ option }">
                {{ option[record[0]] }} {{ (record.length > 1) ? ((showColumnName) ? `(${record[1]}: ${option[record[1]]})` : `(${option[record[1]]})`) : '' }}
            </template>
            <template #option="{ option }">
                {{ option[record[0]] }} {{ (record.length > 1) ? ((showColumnName) ? `(${record[1]}: ${option[record[1]]})` : `(${option[record[1]]})`) : '' }}
            </template>
            <template #noResult>
                <span>Oops! Nem találtunk elemeket. Fontolja meg a keresési lekérdezés megváltoztatását.</span>
            </template>
        </VueMultiselect>
        <div v-if="multiple" class="row">
            <div v-for="(row, index) in data" :key="index" class="col-12">
                <input :name="`${name}[]`" type="hidden" :value="response == 'json' ? JSON.stringify(row) : row[response]">
                <div class="input-group mb-3">
                    <input v-if="record.length > 1" class="form-control" disabled :value="`${row[record[0]]} (${record[1]}: ${row[record[1]]})`">
                    <input v-else v-model="row[record[0]]" class="form-control" disabled>
                    <span class="input-group-text p-0 border-0">
                        <button class="btn btn-danger" @click="removeItem(index)"><i class="far fa-trash-alt" /></button>
                    </span>
                </div>
            </div>
        </div>
        <template v-else>
            <template v-if="data.length > 0">
                <input :id="id" :key="index" :name="name" type="hidden" v-for="(row, index) in data" :value="response == 'json' ? JSON.stringify(row) : row[response]">
            </template>
            <input v-else :id="id" :name="name" type="hidden" :value="response == 'json' ? '[]' : ''">
        </template>
    </div>
</template>

<script>
    //
    import VueMultiselect from 'vue-multiselect';

    //
    export default {
        components: {
            VueMultiselect
        },
        inject: ['$baseUrl'],
        props: {
            modelRoute:  String,
            attr:        [ Array, Object, String ],
            record:      Array,
            name:        String,
            id:          String,
            cssClass:    {
                type: String,
                default: ''
            },
            method:      {
                type: String,
                default: ''
            },
            response:    {
                type: String,
                default: 'json'
            },
            value:       {
                type: Array,
                default: () => []
            },
            placeholder: {
                type: String,
                default: 'Válasszon egy opciót'
            },
            multiple: {
                type: Boolean,
                default: false
            },
            showColumnName: {
                type: Boolean,
                default: true
            },
        },
        data () {
            return {
                data: [],
                mselect: null,
                options:  [],
            }
        },
        watch: {
            'mselect': function() {
                if (!this.multiple) {
                    this.data = (this.mselect == null) ? [] : [this.mselect];
                }
            }
        },
        created () {
            //
            if (this.response == 'json' || (this.response != 'json' && this.multiple)) {
                this.data = this.value;
                if (!this.multiple) {
                    this.mselect = this.value;
                }
            }

            //
            axios.get(`${this.$baseUrl}/ajax/autocomplete`, {
                    params: {
                        modelRoute:  this.modelRoute,
                        record:      this.record,
                        method:      this.method,
                        attr:        this.attr == null ? '' : this.attr
                    }
                })
                .then(resp => {
                    this.options = resp.data;
                    if (this.response != 'json' && this.value != '' && !this.multiple) {
                        let find = this.options.filter(selected => { if (selected[this.response] == this.value) return selected} );
                        this.data = find;
                        if (!this.multiple) {
                            this.mselect = find[0];
                        }
                    }
                })
                .catch(err => console.log(err));
        },
        methods: {
            select(selectedOption, id) {
                if (this.multiple) {
                    this.data.push(selectedOption);
                }
                //this.$emit('selected', selectedOption, id);
            },
            removeItem(index) {
                this.data.splice(index, 1);
            },
        },
    }

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
