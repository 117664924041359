window._ = require('lodash');

import $ from 'jquery';
window.$ = $;

//import * as coreui from '@coreui/coreui-pro';
window.coreui = require('@coreui/coreui-pro');

try {
    require('bootstrap');
    require('datatables.net-bs5');
} catch (e) {}

// Tinymce
import tinymce from 'tinymce';
import 'tinymce/icons/default'
import 'tinymce/models/dom';
import 'tinymce/themes/silver/theme';
// plugins
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';
import 'tinymce/plugins/preview';

// Dropzone
import { Dropzone } from "dropzone";
Dropzone.autoDiscover = false;
window.Dropzone = Dropzone;

import Swal from 'sweetalert2';
window.Swal = Swal;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
