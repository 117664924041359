require('./bootstrap');

//
$('.sidebar-toggler').on('click', () => axios('/ajax/sidebar-minimized'));

//
import { createApp } from 'vue';
//
import Autocomplete from './plugins/Autocomplete';

//
const app = createApp({
    components: {
        //RoleCreate
    }
});
app.provide('$baseUrl', 'http://localhost')
app.use(Autocomplete);
//
app.mount('#app');
